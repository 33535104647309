<template>
    <v-container>
        <v-row>
            <v-col class="col-12 col-xl-12  mx-auto">
                <v-row>
                    <v-col class="col-12 col-sm-6 col-lg-4">
                        <v-card
                                class="transparent glass--bg"
                                @click="tabIdx = 0"
                        >
                            <v-card-title>
                                Ventende ordrer
                            </v-card-title>
                            <v-card-text class="text-h1 text-right white--text">
                                {{ordersReceived}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="col-12 col-sm-6 col-lg-4">
                        <v-card
                                class="transparent glass--bg"
                                @click="tabIdx = 1"
                        >
                            <v-card-title>
                                Ordrer under behandling
                            </v-card-title>
                            <v-card-text class="text-h1 text-right white--text">
                                {{ordersProcessing}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col class="col-12 col-sm-6 col-lg-4">
                        <v-card
                                class="transparent glass--bg"
                                @click="tabIdx = 2"
                        >
                            <v-card-title>
                                Gennemførte ordrer
                            </v-card-title>
                            <v-card-text class="text-h1 text-right white--text">
                                {{ordersCompleted}}
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-tabs-items v-model="tabIdx" class="transparent pa-0">
                            <v-tab-item eager>
                                <orders-received @ordersFetched="ordersReceived = $event"/>
                            </v-tab-item>
                            <v-tab-item eager>
                                <orders-processing @ordersFetched="ordersProcessing = $event"/>
                            </v-tab-item>
                            <v-tab-item eager>
                                <orders-completed @ordersFetched="ordersCompleted = $event"/>
                            </v-tab-item>
                        </v-tabs-items>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import api from "../services/api";
    import ReviewOrder from "../components/orders/reviewOrder";
    import CompleteOrder from "../components/orders/completeOrder";
    import OrdersReceived from "../components/orders/ordersReceived";
    import OrdersProcessing from "../components/orders/ordersProcessing";
    import OrdersCompleted from "../components/orders/ordersCompleted";

    export default {
        name: "Orders.vue",
        components: {OrdersCompleted, OrdersProcessing, OrdersReceived, CompleteOrder, ReviewOrder},
        data() {
            return {
                orders: [],
                ordersReceived: 0,
                ordersProcessing: 0,
                ordersCompleted: 0,
                cvr: '',
                statusMessage: {
                    received: 'Modtaget',
                    processing: 'Behandles',
                    completed: 'Færdig',
                },
                tabIdx: 0,
            }
        },
        methods: {},
        mounted() {
        },
        computed: {},
        watch: {}
    }
</script>

<style scoped>

</style>