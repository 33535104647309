<template>
    <div>
        <v-dialog max-width="1140" v-model="reviewDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    @click="reviewDialog"
                    x-small
                    outlined
                >Gennemgå
                </v-btn>
            </template>
            <v-card class="glass--bg-overlay" elevation="0">
                <v-toolbar dense elevation="0">
                    <v-toolbar-title>Ordre fra {{newOrder.companyName}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-card-subtitle>{{newOrder.articles.length}} artikler på {{newOrder.domain}}</v-card-subtitle>
                    <v-list color="transparent">
                        <v-list-item
                            v-for="(article,idx) in newOrder.articles"
                            :key="article.id"
                        >
                            <v-list-item-content>
                                <v-row>
                                    <v-col>
                                        <v-text-field
                                            v-model="newOrder.articles[idx].title"
                                            label="Titel"
                                            dense
                                            @change="changed = true"/>
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="newOrder.articles[idx].anchor"
                                            label="Ankertekst"
                                            dense
                                            @change="changed = true"
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="newOrder.articles[idx].url"
                                            label="Url"
                                            dense
                                            @change="changed = true"
                                        />
                                    </v-col>
                                    <v-col>
                                        <v-text-field
                                            v-model="newOrder.articles[idx].publishDate"
                                            label="Udgivelse"
                                            dense
                                            @change="changed = true"
                                        />
                                    </v-col>
                                </v-row>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-text-field label="Kommentar til kunde ved godkendelse" v-model="confirmComment"></v-text-field>
                    <v-card-actions>
                        <v-btn
                            @click="confirmOrder()"
                            :loading="confirmationLoading"
                            :disabled="confirmationLoading"
                        >Godkend ordre
                        </v-btn>
                    </v-card-actions>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import api from "../../services/api";
    import {bus} from "../../main";

    export default {
        name: "reviewOrder",
        props: ['order'],
        data() {
            return {
                confirmComment: 'Din ordre er blevet godkendt.',
                changed: false,
                originalOrder: [],
                newOrder: [],
                confirmationLoading: false,
                reviewDialog: false,
            }
        },
        methods: {
            confirmOrder() {
                this.confirmationLoading = true
                api.put('/orders/confirm', {
                    order: this.newOrder,
                    comment: this.confirmComment,
                    changed: this.changed
                })
                    .then(response => {
                        if (response.data.status === 200) {
                            this.confirmationLoading = false
                            this.reviewDialog = false
                            if (response.data.message === "orders/confirmed") {
                                this.$emit('orderConfirmed')
                            }
                            this.$store.dispatch('toasts/toastSuccess', 'Bestilling godkendt')
                            bus.$emit('orderUpdate', true)
                        }
                    })
            },
            log(obj) {
                console.log(obj)
            }
        },
        created() {
            this.newOrder = this.order
        }
    }
</script>

<style scoped>

</style>