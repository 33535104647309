<template>
    <div>
        <v-card-title>Færdige ordrer</v-card-title>
        <v-card class="glass--bg pa-0">
            <v-data-table
                    :items="orders"
                    :headers="headersCompleted"
                    class="transparent"
                    sort-desc
                    sort-by="createdDate"
                    dense
            >
                <template v-slot:item.shippedDate="{item}">
                    {{formatDate(item.modifiedDate)}}
                </template>

                <template v-slot:item.amount="{ item }">
                    {{ item.articles.length }}
                </template>
            </v-data-table>
        </v-card>
    </div>
</template>

<script>
    import api from "../../services/api";
    import {bus} from "../../main";

    export default {
        name: "ordersCompleted",
        data() {
            return {
                orders: [],
                headersCompleted: [
                    {text: 'Dato sendt', value: 'shippedDate', width: 140},
                    {text: 'Kunde', value: 'companyName', width: 220},
                    {text: 'Bestilling', value: 'domain', width: 120},
                    {text: 'Artikler', value: 'amount', align: 'center', width: 108},
                    {text: 'Stk. pris', value: 'unitPrice', align: 'right', width: 120},
                    {text: 'Total', value: 'total', align: 'right', width: 140},
                ],
            }
        },
        methods: {
            getOrders() {
                api.get('/orders/completed')
                    .then(response => {
                        console.log(response.data)
                        this.orders = response.data
                        this.$emit('ordersFetched', response.data.length)
                    })
            },
            formatDate(dateString) {
                const [Y, m, d] = dateString.split(' ')[0].split('-')
                return `${d}-${m}-${Y}`
            },
        },
        mounted() {
            this.getOrders();
        },
        created() {
            bus.$on('orderUpdate', (e) => {
                this.getOrders()
            })
        }
    }
</script>

<style scoped>

</style>