var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',[_vm._v("Ventende ordrer")]),_c('v-card',{staticClass:"glass--bg pa-0"},[_c('v-data-table',{staticClass:"transparent",attrs:{"items":_vm.orders,"headers":_vm.headersReceived,"sort-desc":"","sort-by":"createdDate","dense":""},scopedSlots:_vm._u([{key:"item.reviewOrder",fn:function(ref){
var item = ref.item;
return [_c('review-order',{attrs:{"order":item},on:{"orderConfirmed":_vm.getOrders}})]}},{key:"item.domain",fn:function(ref){
var item = ref.item;
return [_c('span',{staticStyle:{"white-space":"nowrap","text-overflow":"ellipsis","overflow":"hidden"}},[_vm._v(_vm._s(item.domain))])]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.createdDate))+" ")]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.articles.length)+" ")]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }