<template>
    <div>
        <v-dialog max-width="1140" v-model="reviewDialog">
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-on="on"
                    @click="reviewDialog"
                    x-small
                    outlined
                >Udfør
                </v-btn>
            </template>
            <v-card class="gradient--background" elevation="0">
                <v-toolbar dense elevation="0">
                    <v-toolbar-title>Ordre fra {{newOrder.companyName}}</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-card-subtitle class="pl-0 subtitle-2">{{newOrder.articles.length}} artikl(er) på {{newOrder.domain}}</v-card-subtitle>
                    <v-list
                        color="transparent"
                        style="border:3px solid rgba(255,255,255,0.55) !important"
                        class="mb-4"
                    >
                        <v-list-item
                            v-for="(article,idx) in newOrder.articles"
                            :key="article.id"
                        >
                            <v-list-item-content>
                                <v-form
                                    lazy-validation
                                    ref="entryForm"
                                    @submit.prevent="completeOrder"
                                >
                                    <v-row>
                                        <v-col>
                                            <v-text-field
                                                v-model="newOrder.articles[idx].title"
                                                label="Titel"
                                                dense
                                                validate-on-blur
                                                required
                                                :rules="validation.titleRules"
                                            />
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                v-model="newOrder.articles[idx].anchor"
                                                label="Ankertekst"
                                                dense
                                                required
                                                validate-on-blur
                                                :rules="validation.anchorRules"
                                            />
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                v-model="newOrder.articles[idx].url"
                                                label="Url"
                                                dense
                                                required
                                                validate-on-blur
                                                :rules="validation.urlRules"
                                            />
                                        </v-col>
                                        <v-col>
                                            <v-text-field
                                                v-model="newOrder.articles[idx].publishDate"
                                                label="Udgivelse"
                                                dense
                                                required
                                            >
                                                <template v-slot:prepend-inner>
                                                    <date-time-picker
                                                        :dateOnly="true"
                                                        :preSelected="newOrder.articles[idx].publishDate"
                                                        @chosenDate="newOrder.articles[idx].publishDate = $event"
                                                    />
                                                </template>
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                    <v-text-field label="Kommentar til kunde ved udført ordre" v-model="confirmComment"></v-text-field>
                    <p>Der sendes mail til kunden med ovenstående information, sørg for de er korrekte.</p>
                </v-card-text>
                <v-card-actions class="pl-6">
                    <v-checkbox
                        label="Al information er indtastet korrekt"
                        v-model="infoIsCorrect"
                    />
                    <v-spacer/>
                    <v-btn
                        @click="completeOrder"
                        :loading="confirmationLoading"
                        :disabled="confirmationLoading || !infoIsCorrect"
                    >Udfør ordre (luk)
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import api from "../../services/api";
    import DateTimePicker from "../dateTimePicker/dateTimePicker";
    import {bus} from "../../main";

    export default {
        name: "completeOrder",
        components: {DateTimePicker},
        props: ['order'],
        data() {
            return {
                confirmComment: 'Din ordre er blevet gennemført.',
                originalOrder: [],
                newOrder: [],
                confirmationLoading: false,
                reviewDialog: false,
                infoIsCorrect: false,
                validation: {
                    titleRules: [
                        v => !!v || 'Titel er påkrævet',
                        v => (v && v.length >= 5) || 'Min.  5 bogstaver',
                    ],
                    anchorRules: [
                        v => !!v || 'Ankerværdi er påkrævet',
                        v => (v && v.length >= 2) || 'Min. 2 bogstaver',
                    ],
                    urlRules: [
                        v => !!v || 'URL er påkrævet',
                        v => !!v.match(new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)?/gi)) || 'Url ikke gyldig',
                    ],
                    dateRules: [
                        v => !!v || 'Udgivelsesdato er påkrævet',
                        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
                    ],
                }
            }
        },
        methods: {
            completeOrder() {
                this.confirmationLoading = true
                if (!this.$refs.entryForm[0].validate()) {
                    this.$store.dispatch('toasts/toastWarning', 'Udfyld venligst alle felter korrekt.')
                    this.confirmationLoading = false
                    return
                }
                api.put('/orders/complete', {
                    order: this.newOrder,
                    comment: this.confirmComment,
                })
                    .then(response => {
                        console.log(response.data)
                        if (response.status === 200) {

                            this.confirmationLoading = false
                            this.reviewDialog = false
                            if (response.data.message === "orders/completed") {
                                this.$emit('orderCompleted')
                            }
                            this.$store.dispatch('toasts/toastSuccess', 'Bestilling godkendt')
                            bus.$emit('orderUpdate', true)
                        }
                    })
                    .catch(err => {
                        this.$store.dispatch('toasts/toastWarning', 'Der sketee en fejl')
                        this.confirmationLoading = false
                        console.log(err)
                    })
            },
            log(obj) {
                console.log(obj)
            }
        },
        created() {
            this.newOrder = this.order
        }
    }
</script>

<style scoped>

</style>